<template>
    <div
        class="business-structure__add"
        @click="$emit('click')"
    >
        <span>
            <i class="fa-solid fa-plus" />
            <slot />
        </span>
    </div>
</template>

<script>
export default {
    name: "BaseAddCard",
}
</script>

<style lang="scss">
.business-structure__add {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    min-height: 66px;
    position: relative;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%237FA9EA' stroke-width='2' stroke-dasharray='7%2c 11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
    cursor: pointer;
    color: #296FDC;

    i.fa-solid {
        width: 17px;
        font-weight: 400;
    }
}
</style>