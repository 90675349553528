<template>
    <div>
        <div
            v-if="businessStructure.id"
            class="business-structure__top-right"
        >
            <el-form ref="setIsCompleteForm">
                <el-form-item
                    prop="isComplete"
                    class="fbs-is-complete-form-item"
                    :error="validateBusinessStructureName"
                >
                    <el-button
                        v-if="!businessStructure.is_complete"
                        size="mini"
                        class="fbs-mark-as-complete"
                        :disabled="invalidParent"
                        @click="setIsComplete(true)"
                    >
                        Mark as Complete
                    </el-button>
                    <span
                        v-else
                        class="fbs-is-complete print:hidden"
                    >
                        <i class="fa-solid fa-check" />
                        Complete
                        <i
                            class="fa-solid fa-pencil"
                            @click="setIsComplete(false)"
                        />
                    </span>
                </el-form-item>
            </el-form>
        </div>

        <h4>{{ header }}</h4>

        <el-row
            :gutter="8"
            type="flex"
            justify="center"
        >
            <el-col :span="8">
                <BaseEntityCard
                    v-if="businessStructure.id && businessStructure.name"
                    :name="businessStructure.name"
                    :commands="parentCommands"
                    :allow-edit="allowEdit"
                    @command="handleParentCommand"
                />
                <BaseAddCard
                    v-else-if="allowEdit"
                    @click="$emit('parent-command', {
                        command: 'edit'})"
                >
                    Name Business Structure
                </BaseAddCard>
            </el-col>
        </el-row>

        <el-divider />

        <el-row :gutter="8">
            <el-col
                v-for="entity in businessStructure.entities"
                :key="entity.id"
                :span="8"
            >
                <BaseEntityCard
                    :name="entity.name"
                    :purposes="entity.purposes"
                    :commands="entityCommands"
                    :allow-edit="allowEdit"
                    @command="handleEntityCommand(entity, $event)"
                />
            </el-col>

            <el-col
                v-if="allowEdit"
                key="add"
                :span="8"
            >
                <BaseAddCard @click="$emit('entity-command', {command: 'create', entity: null})">
                    Add Entity
                </BaseAddCard>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {globals} from "Figured/Assets/Modules";
import BaseEntityCard from "BusinessStructure/Assets/Components/BaseEntityCard";
import BaseAddCard from "BusinessStructure/Assets/Components/BaseAddCard";
import {isNull} from "lodash";

export default {
    name: "BaseDisplayTree",
    components: {
        BaseAddCard,
        BaseEntityCard,
    },

    props: {
        businessStructure: {
            type: Object,
            required: true
        },
        validationMessages: {
            type: Object,
            required: true,
        },
        allowEdit: {
            type: Boolean,
            required: true
        },
        isActiveFarmCommercialManager: {
            type: Boolean,
            required: false,
            default: null
        },
    },

    data() {
        return {
            showNoParentError: false
        }
    },

    computed: {
        parentCommands() {
            return [
                {key: "edit", label: "Edit"},
                {key: "delete", label: "Delete"},
            ]
        },
        entityCommands() {
            return [
                {key: "edit", label: "Edit"},
                {key: "delete", label: "Delete"}
            ]
        },
        validateBusinessStructureName() {
            const serverValidation = (this.validationMessages["business_structure.name"] || [])[0];
            const uiValidation = this.invalidParent && "Business Structure requires a name";

            return serverValidation || uiValidation || "";
        },
        invalidParent() {
            return this.showNoParentError && !this.businessStructure.name && this.businessStructure.entities.length > 1;
        },
        header() {
            const isActiveFarmCommercialManager = !isNull(this.isActiveFarmCommercialManager)
                ? this.isActiveFarmCommercialManager
                : globals.isActiveFarmCommercialManager();

            return isActiveFarmCommercialManager ? "Business Structure" : "Farm Business Structure";
        },
    },

    methods: {
        handleParentCommand(command) {
            this.$emit("parent-command", {command});
        },
        handleEntityCommand(entity, command) {
            this.$emit("entity-command", {command, entity});
        },
        setIsComplete(isComplete) {
            this.showNoParentError = true;
            if (! this.invalidParent) {
                this.$emit("set-is-complete", isComplete);
            }
        },
    }
}
</script>

<style lang="scss">
.fbs-mark-as-complete {
    border-color: #B8EDD1;
    color: #13C366;
}
.fbs-mark-as-complete:hover {
    background-color: #fff;
}

.fbs-is-complete {
    color: #90A4AE;
}

.fbs-is-complete-form-item {
    margin: 0;
}

.fbs-is-complete > .fa-check {
    font-weight: 400;
}
.fbs-is-complete > .fa-pencil {
    color: #296FDC;
    margin-left: 5px;
    cursor: pointer;
}

.business-structure__top-right {
    position: relative;
    float: right;
}
</style>