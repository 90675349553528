<template>
    <div class="business-structure__entity">
        <el-card shadow="hover">
            <EntityDropdown
                v-if="allowEdit"
                :commands="commands"
                @command="handleCommand"
            />

            <div class="business-structure__entity__purposes">
                <EntityPurpose
                    v-for="purpose in purposes"
                    :key="purpose"
                    :purpose="purpose"
                />
            </div>

            <h5>{{ name }}</h5>
        </el-card>
    </div>
</template>

<script>
import EntityPurpose from "BusinessStructure/Assets/Components/BaseEntityPurpose";
import EntityDropdown from "BusinessStructure/Assets/Components/BaseEntityDropdown";

export default {
    name: "BaseEntityCard",
    components: {EntityDropdown, EntityPurpose},
    props: {
        name: {
            type: String,
            required: true
        },
        purposes: {
            type: Array,
            required: false,
            default: () => []
        },
        commands: {
            type: Array,
            required: true
        },
        allowEdit: {
            type: Boolean,
            required: true
        }
    },

    methods: {
        handleCommand(command) {
            this.$emit("command", command);
        }
    }
}
</script>

<style lang="scss">
.business-structure__entity {
    .el-tag {
        margin: 0 7px 3px 0;
    }

    .el-card {
        border-color: #CFD8DC;
    }

    .el-card__body {
        padding: 10px;
        min-height: 66px;
        position: relative;
    }

    h5 {
        margin: 10px 0 0 0;
    }

    &__purposes {
        min-height: 6px;
        padding-right: 10px;
    }
}
</style>