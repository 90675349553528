<template>
    <el-tag
        size="mini"
        :color="tagColor"
        effect="dark"
    >
        {{ purpose }}
    </el-tag>
</template>

<script>
export default {
    name: "BaseEntityPurpose",
    props: {
        purpose: {
            type: String,
            required: true
        }
    },
    computed: {
        tagColor() {
            return {
                "Farm Trading": "#A5D8B5",
                "Non-Farm Trading": "#70BFD6",
                "Land Owner": "#B0BEC5",
            }[this.purpose] || "#B0BEC5";
        }
    }
}
</script>

<style scoped>
.el-tag {
    color: #fff;
    border: none;
    padding: 1px 5px 2px;
}
</style>